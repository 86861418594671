<template>
  <div>
    <input
      id="search-place-field"
      class="basic--input"
      ref="searchPlacesField"
      type="text"
      v-model="localQuery"
      :placeholder="$t('addListing.pin.placeholder')"
    />
    <div
      class="wrapper--suggest"
      :class="{ 'wrapper--suggest-add': form === 'add' }"
      v-show="autocompleteSuggestions && autocompleteSuggestions.length > 0"
    >
      <place-suggestion
        v-for="suggestion in autocompleteSuggestions"
        :key="suggestion.id"
        :suggestion="suggestion"
        @suggestionClicked="suggestionClicked"
        :hideSuggestion="hideSuggestion"
      />
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import LodashMixin from '@/mixins/lodash';
const PlaceSuggestion = () => import('@/components/listing-form/sect1/map/place-sugestion.vue');
export default {
  name: 'search-places',
  mixins: [LodashMixin],
  components: { PlaceSuggestion },
  data() {
    return {
      hideSuggestion: false,
      autocompleteService: null,
      sessionToken: null,
      autocompleteSuggestions: [],
      shouldSearch: true,
      localQuery: null,
    };
  },
  created() {
    // eslint-disable-next-line no-undef
    this.sessionToken = new google.maps.places.AutocompleteSessionToken();
    // eslint-disable-next-line no-undef
    this.autocompleteService = new google.maps.places.AutocompleteService();
    this.debounceGetSuggestion = this.debounce(this.getSuggestion, 500);
  },
  computed: {
    ...mapGetters({
      // fetchingEditListingData:'listingForm/fetchingEditListingData',
      // loadPropertyDataFromList:'listingForm/property/loadPropertyDataFromList',
      propertyUuidForm: 'v2/listingForm/sect1/location/propertyUuidForm',
    }),
    ...mapState({
      form: state => state.v2.listingForm.form,
      propertyName: state => state.v2.listingForm.sect1.location.propertyName,
    }),
    loadingMap: {
      get() {
        return this.$store.state.v2.listingForm.sect1.location.loadingMap;
      },
      set(value) {
        this.$store.commit('v2/listingForm/sect1/location/SET_LOADING_MAP', value);
      },
    },
    autocompleteQuery: {
      get() {
        return this.$store.state.v2.listingForm.sect1.location.autocompleteQuery;
      },
      set(value) {
        this.$store.commit('v2/listingForm/sect1/location/SET_AUTO_COMPLETE_QUERY', value);
        this.localQuery = value;
      },
    },
  },
  watch: {
    // eslint-disable-next-line no-unused-vars
    localQuery(value) {
      if (this.shouldSearch) {
        this.debounceGetSuggestion();
      } else {
        this.shouldSearch = true;
      }
    },
  },
  mounted() {
    window.addEventListener('click', this.windowClickListener);
  },
  beforeDestroy() {
    window.removeEventListener('click', this.windowClickListener);
  },
  methods: {
    windowClickListener(e) {
      if (!document.querySelector('.wrapper--suggest').contains(e.target)) {
        this.hideSuggestion = !this.$refs.searchPlacesField.contains(e.target);
      }
    },
    clearQuery() {
      this.localQuery = null;
    },
    async getPlaceDetail(placeId) {
      this.loadingMap = true;
      try {
        const { data } = await this.$http.get('/api/v1/maps/getPlaceDetail', {
          params: {
            placeId,
          },
        });
        if (data.data) {
          console.log('asd', data.data);
          const lat = data.data.location.lat;
          const lng = data.data.location.lng;
          this.$store.commit('v2/listingForm/sect1/location/CHANGE_LATITUDE', lat);
          this.$store.commit('v2/listingForm/sect1/location/CHANGE_LONGITUDE', lng);
          this.$store.commit(
            'v2/listingForm/sect1/location/CHANGE_ADDRESSLINE2',
            data.data.address_line_2,
          );
          this.$store.commit(
            'v2/listingForm/sect1/location/SET_POSTAL_CODE',
            data.data.postal_code,
          );
          this.$store.dispatch(
            'v2/listingForm/sect1/regionalListingForm/setRegionalData',
            data.data.regional_data,
          );
          this.$store.commit('v2/listingForm/sect1/SET_OPEN_ADDRESS_SECTION', true);
          this.$store.commit('v2/listingForm/sect1/SET_OPEN_AMENITIES', true);
          // DO NOT AUTO SET PROPERTY NAME AFTER SELECTING MAP LOCATION
          // if (this.propertyUuidForm == null || this.propertyUuidForm === '') {
          //   this.$store.commit(
          //     'v2/listingForm/sect1/location/SET_PROPERTY_NAME',
          //     this.autocompleteQuery,
          //   );
          // }
          this.$emit('placeDetailFound');
        }
      } finally {
        this.loadingMap = false;
      }
    },
    suggestionClicked(suggestion) {
      this.autocompleteSuggestions = [];
      this.shouldSearch = false;
      this.autocompleteQuery = suggestion.description;
      this.getPlaceDetail(suggestion.place_id);
    },
    getSuggestion() {
      if (this.localQuery) {
        this.autocompleteService.getPlacePredictions(
          {
            input: this.localQuery,
            sessionToken: this.sessionToken,
            componentRestrictions: { country: 'sg' },
          },
          this.displaySuggestions,
        );
      } else {
        this.autocompleteSuggestions = [];
      }
    },
    displaySuggestions(predictions, status) {
      if (status != window.google.maps.places.PlacesServiceStatus.OK) {
        this.autocompleteSuggestions = [];
        return;
      }
      this.autocompleteSuggestions = predictions;
    },
  },
};
</script>

<style scoped></style>
